*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --height-header: 3rem;
  --height-home: 3rem;

  --clr-primary: #da2535;
  --clr-primary-alt: #c42130;
  --clr-primary-light: #f26f7a;
  --clr-title: #161212;
  --clr-text: #5b5757;
  --clr-text-dark: #504c4c;
  --clr-text-light: #8f8a8a;
  --clr-gray: #f2f2f2;
  --clr-body: #fefbfb;
  --clr-container: #fff;
  --clr-green: #27ae60;
  --clr-green-alt: #239051;
  --clr-green-light: #00a326;
  --clr-fucsia: #c33764;
  --clr-fucsia-alt: #9b2a4d;
  --clr-polygon: #e0eaf23c;
  --swiper-theme-color: #da2535 !important;

  --ff: "Poppins", sans-serif;
  --fs-small: 0.813rem;
  --fs-regular: 1rem;
  --fs-normal: 1.125rem;
  --fs-big: 1.25rem;
  --fs-biggest: 1.375rem;
  --fs-huge: 1.5rem;
  --fs-title: 1.5rem;

  --fw-thin: 100;
  --fw-regular: 400;
  --fw-semibold: 600;
  --fw-bold: 700;

  --m-1: 0.5rem;
  --m-2: 1rem;
  --m-3: 1.5rem;
  --m-4: 2rem;
  --m-5: 2.5rem;
  --m-6: 3rem;

  --border-radius: 4px;

  --z-index-tooltip: 10;
  --z-index-fixed: 100;
}

html {
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
ul,
p {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

ul li:before {
  content: '✓';
  margin-right: 1rem;
}

a {
  text-decoration: none;
}

button,
input {
  border: none;
  outline: none;
}

button {
  cursor: pointer;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  font-family: var(--ff);
  font-size: var(--fs-normal);
  background-color: var(--clr-body);
  color: var(--clr-text);
  line-height: 1.6;
}

p {
  font-size: var(--fs-regular);
}

/* Helpers classes */
.l-grid {
  display: grid;
  gap: 1rem;
}

.l-flex {
  display: flex;
  gap: 1rem;
}

.l-gap-0 {
  gap: 0;
}

.l-flex-r {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.l-fd-col {
  flex-direction: column;
}

.l-jcc {
  justify-content: center;
}

.l-jcfe {
  justify-content: flex-end;
}

.l-jic {
  justify-items: center;
}

.l-aic {
  align-items: center;
}

.l-taic {
  text-align: center;
}

.l-mw-90 {
  max-width: min(90%, 70rem);
}

.l-ass {
  align-self: start;
}

.l-pad-2 {
  padding: 0 2rem;
}

.l-padv-2 {
  padding: 2rem;
}

.l-pad-0 {
  padding: 0 !important;
}

.l-pad-1 {
  padding: 1em;
}

.l-back-white {
  background-color: var(--clr-container);
}

.l-back-body {
  background-color: var(--clr-body);
}

.l-text-light {
  color: var(--clr-container);
}

.l-text-dark {
  color: var(--clr-text-dark) !important;
}

.l-text-primary {
  color: var(--clr-primary);
}

/* Components */
.card {
  background-color: var(--clr-container);
  box-shadow: rgb(0 0 0 / 12%) 0px 2px 8px;
  border-radius: 0.5em;
  overflow: hidden;
  transition: 0.2s;
}

.card__img {
  height: 350px;
}

.card img {
  width: 100%;
}

.card-top,
.card-bottom {
  border: 1px solid #c4c4c4;
  border-radius: 0.5em;
  overflow: hidden;

  /* flex: 1; */
}

.card-top {
  flex: 3;
}

.card-bottom {
  flex: 4;
}

.card-header {
  padding: 1.5rem;
  background-color: #f73d4d;
}

.card-header p {
  font-size: 2rem;
  color: var(--clr-container);
}

.card-bottom p {
  font-size: 1.8rem;
  font-weight: var(--fw-semibold);
  color: var(--clr-text-dark);
}

.card-new {
  background-color: var(--clr-primary);
  color: var(--clr-text);
  border-radius: 1em;
  padding: 1rem;

  display: grid;
  gap: 1rem;
}

.card-new-header {
  display: flex;
}

.card-new-header p {
  flex: 70%;
}

.card-new-icons {
  flex: 40%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--clr-text-dark);
}

.card-new-icons * {
  font-size: 3rem;
}

.card-new__content {
  padding: 1rem;
  border-radius: 0.5em;
  background-color: var(--clr-container);
  overflow: hidden;
  line-height: 1.2;
}

.card-new__content p {
  font-size: var(--fs-normal);
  /* text-align: center; */
  color: var(--clr-text);
}

.card-title {
  font-size: var(--fs-big);
}

.card-title-big {
  /* padding-left: 0.5em; */
  font-size: var(--fs-title);
  font-weight: var(--fw-semibold);
  color: var(--clr-text-dark);
}

.card__content,
.card-bottom {
  padding: 1rem;
  font-size: var(--fs-big);
}

.card__content ul li {
  font-size: var(--fs-regular);
  font-weight: var(--fw-regular);
}

.paquete {
  background-color: var(--clr-container);
  border-radius: 0.5em;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

/* Servicios Auxiliares */
.img-grid {
  display: none;
  transition: 0.3s;
}

/* Header and nav */
.header {
  width: 100%;
  height: var(--height-header);
  position: fixed;
  top: 0;
  left: 0;

  background-color: var(--clr-container);
  box-shadow: 0 2px 6px rgba(65, 11, 16, 0.15);
  z-index: var(--z-index-fixed);
}

.nav-bd-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-inline: var(--m-3);
}

.nav__menu {
  position: absolute;
  top: 0;
  right: -70%;

  display: flex;
  align-items: center;

  height: 100vh;
  width: 70%;
  background-color: var(--clr-body);
  transition: 0.4s;
  box-shadow: 0 2px 6px rgba(65, 11, 16, 0.05);
}

.nav__list {
  flex: 1;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  text-align: center;
}

.nav__logo,
.nav__link {
  color: var(--clr-primary-light);
}

.nav__logo div {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.nav__logo div img {
  max-width: 2rem;
}

.nav__logo div span {
  color: var(--clr-text-dark);
}

.nav__icon {
  display: flex;
  justify-content: center;
  font-size: var(--fs-big);
  cursor: pointer;
}

.active {
  color: var(--clr-text-dark);
}

/* Home */
.home {
  margin-top: var(--height-header);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.polygon-content-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5em;
}

.polygon-content h1 {
  font-size: 1.5rem;
}

.polygon-content img {
  aspect-ratio: 1/1;
  height: 1.5rem;
}

.polygon::before,
.polygon::after {
  background-color: #E0EAF2;
  content: "";
  opacity: 0.75;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: 0.4s;
}

.polygon-dark-bg::before,
.polygon-dark-bg::after {
  background-color: var(--clr-title);
}

.polygon::before {
  clip-path: polygon(0 25%, 100% 50%, 100% 100%, 0% 100%);
}

.polygon::after {
  clip-path: polygon(0 50%, 100% 25%, 100% 100%, 0% 100%);
}

.polygon-content {
  color: var(--clr-container);
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  text-align: center;
}


.polygon-bg {
  background-color: var(--clr-polygon);
}

.polygon-bg-dark {
  background-color: var(--clr-title);
}

.swiper {
  width: 100%;
  margin-top: var(--height-header);
}

.swiper-slide {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--clr-container);
  transition: 0.3s;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: var(--clr-primary);
}

.swiper-pagination-bullet-active {
  color: var(--clr-primary-light);
  background-color: var(--clr-primary-light);
}

.swiper-buttons {
  max-width: 250px;
  padding: 0.25em;
  background-color: #1612127e;
  z-index: 99999;
  position: absolute;
  bottom: 15px;
  left: calc(35% - 60px);
  color: var(--clr-container);
  font-size: small;
}

progress {
  -webkit-appearance: none;
  height: 0.25em;
}

::-webkit-progress-value {
  background-color: var(--clr-primary);
}

::-webkit-progress-bar {
  background-color: var(--clr-container);
}

::-moz-progress-bar {
  background-color: var(--clr-primary);
}

/* Clientes */
.clientes {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Servicios */
.servicios,
.nosotros {
  display: grid;
  justify-items: center;
}

/* Nosotros */
.nosotros__container {
  display: grid;
  /* max-width: min(90%, 70rem); */
}

.nosotros__container>div {
  padding: 1rem 0;
}

.nosotros__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nosotros__mision {
  flex-direction: column;
}

.section__title {
  text-align: center;
  font-size: var(--fs-huge);
  font-weight: var(--fw-semibold);
}

.section-subtitle {
  font-size: var(--fs-biggest);
  font-weight: var(--fw-semibold);
}

.section-description {
  font-size: var(--fs-big);
  font-weight: var(--fw-regular);
}

.sigas-text {
  font-size: var(--fs-normal) !important;
}

.section__title-emphasis {
  font-weight: var(--fw-bold);
  text-align: center;
}

.form-buttons .button-primary {
  flex: 2;
}

.form-buttons .button-green {
  flex: 1;
}

.button-primary {
  background-color: var(--clr-primary);
  padding: 0.5em;
  align-self: center;
  border-radius: 0.25em;
  font-size: var(--fs-normal);
  color: var(--clr-container);
}

.button-primary:hover {
  background-color: var(--clr-primary-alt);
}

.button-green {
  background-color: var(--clr-green);
  padding: 0.5em;
  border-radius: 0.25em;
  align-self: center;
}

.button-green:hover {
  background-color: var(--clr-green-alt);
}

.button-green i {
  font-size: 2rem;
  color: var(--clr-container);
}

.contacto {
  width: 100%;
}

.contacto-superior {
  grid-column: 1;
  grid-row: 1 / 1;
}

.contacto-form {
  width: 100%;
  color: var(--clr-container);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1em;
  transition: 0.3s;
}

.inner-container {
  flex-direction: column;
}

input {
  padding: 1rem;
  border-radius: var(--border-radius);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.form input {
  flex: 1;
}

.form-buttons,
.form-item {
  display: flex;
  justify-content: center;
  gap: 0.5em;
}

.form-item {
  flex-direction: column;
}

.contacto__list {
  width: min(90%, 70rem);
  display: grid;
  gap: 1rem;
}

.list__item {
  display: flex;
  align-items: center;
}

.list__item div {
  padding: 1em;
}

.contacto__icon i {
  font-size: 2rem;
}

.qr {
  aspect-ratio: 1;
}

/* Boton de whatsapp */
._uThfQ {
  color: var(--clr-text-dark);
}

.footer {
  background-color: #161212de;
  padding: 1rem;
}

.footer__list {
  padding: 2rem;
  /* grid-template-columns: repeat(auto-fill, minmax(calc(25% - 1rem), 1fr)); */
}

.footer__list li:before,
.nav__list li:before {
  content: '';
  margin-right: 0;
}

.footer__item,
.footer__item a,
.rrss-icons a,
a {
  color: var(--clr-container);
}

.footer__item a:hover,
.rrss-icons a:hover {
  color: var(--clr-primary);
}

.rrss-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  color: var(--clr-container);
}

.show {
  right: 0;
}

.mt-auto {
  margin-top: auto;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: var(--height-header);
}

.mb-2 {
  margin-bottom: 2rem;
}

.mv-5 {
  margin: var(--height-header) 0;
}

.mh-1 {
  margin-left: 1rem;
}

.mh-2 {
  margin-left: 2rem;
}

.main {
  display: grid;
}

.h100 {
  height: 100%;
}

.main__nosotros {
  display: grid;
  width: min(90%, 70rem);
}

.content-img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-img img {
  /* max-width: 100%;
  object-fit: contain; */
  max-height: 100%;
  width: auto;
}

.content-img-250 img {
  max-width: 250px;
}

.cifras,
.clientes,
.clientes-top {
  margin: 1.5rem 0;
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(2, 1fr);
}

.cifra__item {
  border-radius: var(--border-radius);
  padding: 1em;
}

.cifra__monto {
  color: var(--clr-primary-light);
  font-weight: var(--fw-semibold);
  font-size: 2rem;
}

.cifra__texto {
  font-size: var(--fs-regular);
}

.content__mision,
.nosotros__objetivos,
.nosotros__unica {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Nosotros */
.width-90 {
  width: min(90%, 70rem);
}

.nosotros__unica p:first-child {
  font-size: var(--fs-huge);
  font-weight: var(--fw-bold);
  color: var(--clr-text-dark);
}

.nosotros__unica p:not(first-child) {
  color: var(--clr-primary);
  font-size: var(--fs-normal);
  font-weight: var(--fw-semibold);
}

/* Servicios */
.servicios__container {
  /* display: grid; */
  padding: 4rem 0;
}

.servicios__intro {
  position: relative;
  width: 100%;
}

.section__title {
  width: 100%;
}

.section-content,
.servicios__list,
.servicios-aux-list,
.servicios-seg-list {
  display: grid;
  gap: 1rem;
}

.intro__text {
  position: absolute;
  top: 30%;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: 1em;
  color: var(--clr-container);
}

.relative {
  position: relative;
}

.ver-mas {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c42131d9;
  color: var(--clr-container);
  transition: 0.3s;
}

.ver-mas p {
  font-size: var(--fs-big);
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
  cursor: pointer;
  padding: 1em;
  transition: 0.3s;
}

.btn span {
  font-size: var(--fs-huge);
}

.btn-clear {
  border: 1px solid var(--clr-primary);
  color: var(--clr-primary);
}

.btn-green-light {
  background-color: var(--clr-green-light);
  color: var(--clr-container);
}

.btn-green-light:hover {
  background-color: #037c1f;
}

.btn-fucsia {
  background-color: var(--clr-fucsia);
  color: var(--clr-container);
}

.btn-fucsia:hover {
  background-color: var(--clr-fucsia-alt);
}

.btn-red {
  background-color: var(--clr-primary);
  color: var(--clr-container);
  transition: .3s;
}

.btn-red:hover {
  background-color: var(--clr-primary-alt);
  /* padding: 1em;
  font-size: 1.5rem; */
}

.btn-clear:hover {
  background-color: var(--clr-primary);
  color: var(--clr-container);
}

.sigas,
.sigas-alt {
  padding: 0.25em;
  /* font-weight: var(--fw-semibold); */
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: 0.3s;
}

.sigas {
  color: var(--clr-primary);
}

.red-span {
  padding: 0.25em;
  font-weight: var(--fw-semibold);
  border-radius: var(--border-radius);
  background-color: var(--clr-primary);
  color: var(--clr-container);
}

.sigas-alt {
  background-color: var(--clr-primary);
  color: var(--clr-container);
}

.sigas:hover {
  background-color: var(--clr-primary);
  color: var(--clr-container);
}

.sigas-alt:hover {
  background-color: var(--clr-container);
  color: var(--clr-primary);
}

.text-description {
  max-width: 70ch;
  padding: 2rem;
}

.dark-bg {
  background-color: #161212de;
}

.l-bg-black {
  background-color: var(--clr-title);
}

.l-bg-primary {
  background-color: var(--clr-primary);
}

.l-bg-gray {
  background-color: var(--clr-text);
}

.l-bg-light-gray {
  background-color: var(--clr-gray);
}

.l-bg-txt-dark {
  background-color: var(--clr-text-dark);
}

.l-bg-txt-dark2 {
  background-color: #3f3c3c;
}

.l-bg-linear {
  background: #155799;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right,
      #155799,
      #159957);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #155799,
      #159957);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.l-bg-linear2 {
  background-color: #0b3849;
}

.l-bg-linear3 {
  background-color: #061d26;
}

.btn-gps {
  position: absolute;
  right: 100px;
  bottom: 100px;
}

/* Media queries */
@media only screen and (min-width: 300px) {
  .swiper-buttons {
    display: none;
  }
}

@media only screen and (min-width: 576px) {

  /* Helpers */
  .grid-col-span-2 {
    grid-column: span 2;
  }

  .swiper-slide {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .polygon::before {
    clip-path: polygon(0 0, 100% 50%, 100% 100%, 0% 100%);
  }

  .polygon::after {
    clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
  }

  .polygon-bg-dark {
    background: none;
  }

  .section-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .repeat2 {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 1rem), 1fr));
  }

  .repeat3 {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 1rem), 1fr));
  }

  .repeat4 {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 1rem), 1fr));
  }

  .rrss {
    grid-column: 1/3;
  }

  /* Formulario de contacto */
  .inner-container {
    flex-direction: row;
  }

  .inner-container form {
    flex: 2;
  }

  .inner-container img {
    border-radius: 4px;
  }

  .polygon-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

@media only screen and (max-width: 575px) {

  /* Helpers */
  .grid-superior {
    grid-column: 1;
    grid-row: 1 / 1;
  }

  /* Footer */

  .footer__list {
    padding: 1rem;
  }

  .footer__item {
    text-align: center;
  }

  .footer__item:not(:first-child) {
    display: none;
  }
}

@media only screen and (min-width: 767px) {

  /* Helpers */
  .grid-col-span-2 {
    grid-column: span 2;
  }

  .repeat3 {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(calc(33% - 1rem), 1fr));
  }

  .repeat4 {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(calc(33% - 1rem), 1fr));
  }

  .content-right,
  .content-left {
    grid-column: span 2;
  }

  .card-new {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(33% - 1rem), 1fr));
  }

  .card-new-ul {
    grid-column: 1;
    grid-row: 1 / 4;
  }

  .card-new-ul-left {
    grid-column: 3;
    grid-row: 1 / 4;
  }

  .card-new-img {
    grid-row: 2 / 3;
  }

  .card-new.l-bg-primary,
  .card-new.l-bg-linear,
  .card-new.l-bg-light-gray,
  .card-new.l-bg-gray {
    grid-template-rows: auto 1fr 1fr;
  }

  /* Header */
  .nav__menu {
    position: initial;
    width: 80%;
    height: auto;
    transition: 0.4s;
    background-color: transparent;
    box-shadow: none;
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
  }

  .nav__img,
  .nav__icon,
  .nav__toggle {
    display: none;
  }

  .nav__logo {
    width: 20%;
  }

  .nav__list {
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 3rem;
    padding: 0;
  }

  .nav__link {
    text-transform: initial;
    font-weight: initial;
  }

  /* Footer */
  .rrss {
    grid-column: 1/4;
  }

  /* Home */
  .polygon::before,
  .polygon::after {
    top: 0;
    height: 100%;
    width: 60%;
  }

  .polygon::before {
    clip-path: polygon(0 0, 100% 0, 60% 100%, 0 100%);
  }

  .polygon::after {
    clip-path: polygon(0 0, 60% 0, 100% 100%, 0% 100%);
  }

  .polygon-bg,
  .polygon-bg-dark {
    background-color: transparent !important;
  }

  .polygon-content {
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    gap: 1.5rem;
    padding: 3.5rem;
    text-align: left;
  }

  .polygon-content-title {
    justify-content: start;
    gap: .5em;
  }

  .polygon-content h1 {
    font-size: 1.8rem;
  }

  .polygon-content img {
    height: 1.8rem;
  }

  .polygon-content p {
    font-size: 1.5rem;
  }

  .sigas-alt p {
    font-size: 1.1rem;
  }

  .respaldo-content {
    gap: .5rem;
  }

  .respaldo-content p {
    font-size: 1.2rem;
  }

  /* Nosotros */
  .main__nosotros,
  .contacto__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .main__nosotros>div {
    padding: 2rem 0;
  }

  .cifras {
    grid-template-columns: repeat(4, 1fr);
  }

  .cifra__item {
    flex: 1;
  }

  .clientes {
    grid-template-columns: repeat(3, 1fr);
  }

  .clientes-top {
    grid-template-columns: repeat(2, 1fr);
  }

  .nosotros__container>div {
    padding: 1.5rem;
  }

  /* Servicios */
  .servicios-seg-list {
    gap: 1rem;
  }

  .section-content {
    grid-template-columns: 1fr 3fr;
  }

  .section-content>div {
    padding: 2rem 0;
  }

  .servicios__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  .intro__text {
    top: 50%;
  }

  /* Servicios cards */
  .card:hover .ver-mas {
    left: 0;
  }

  .card__content .btn.btn-clear {
    display: none;
  }

  /* Contacto */
  .contacto {
    padding: 2rem 0;
  }

  .contacto-form {
    width: min(90%, 70rem);
    border-radius: var(--border-radius);
    padding: 4rem 6rem;
  }

  .form-item {
    flex-direction: row;
  }

  .form-item>* {
    flex: 1;
  }

  input::placeholder {
    font-size: var(--fs-big);
  }
}

@media only screen and (min-width: 800px) {
  :root {
    --fs-title: 2rem;
  }

  /* Servicios Auxiliares */
  .servicios-aux-list {
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 1rem), 1fr));
    row-gap: 2.5rem;
  }

  .card-new-icons {
    display: none !important;
  }

  .img-grid {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    gap: 1em;
  }

  .grid-top,
  .grid-top2 {
    flex: 0.6;

    display: flex;
    gap: 0.5em;
  }

  .grid-top div:nth-child(1),
  .grid-top2 div:nth-child(2) {
    flex: 60%;
    display: flex;
    align-items: flex-end;
    margin-bottom: 1em;
  }

  .grid-top div:nth-child(2),
  .grid-top2 div:nth-child(1) {
    flex: 40%;

    display: flex;
    align-items: flex-end;
    margin-bottom: 2em;
  }

  .grid-bottom img {
    flex: 80%;
    display: flex;
    align-items: flex-end;
    margin-bottom: 1em;
  }

  .img-grid img {
    border-radius: 0.5em;
    max-height: 100%;
  }

  /* Nosotros */
  .clientes,
  .clientes-top {
    grid-template-columns: repeat(4, 1fr);
  }

  .swiper-buttons {
    display: initial;
    display: flex;
    gap: 0.5em;
    align-items: center;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}

@media only screen and (min-width: 900px) {

  /* Cards */
  .card-new {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(33% - 1rem), 1fr));
  }

  .card-new-img {
    grid-row: 2 / 4;
  }

  .card__content,
  .card-bottom {
    padding: 1.5rem;
    font-size: var(--fs-big);
  }

  /* Gestion */
  .paquete {
    flex-direction: row;
  }

  /* Home */
  .polygon-content h1 {
    font-size: 2.8rem;
  }

  .polygon-content img {
    height: 2.8rem;
  }

  .polygon-content p {
    font-size: 1.8rem;
  }

  .sigas-alt p {
    font-size: 1.3rem;
  }

  .respaldo-content h1 {
    font-size: 3rem;
  }

  .respaldo-content p {
    font-size: 1.5rem;
  }

  /* Nosotros */
  .clientes {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (min-width: 1200px) {
  .repeat4 {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - 1rem), 1fr));
  }

  /* Home */
  .polygon-content h1 {
    font-size: 3.5rem;
  }

  .polygon-content img {
    height: 3.5rem;
  }
}

@media only screen and (min-width: 1400px) {

  /* Home */
  .polygon-content h1 {
    font-size: 4.5rem;
  }
}